import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CanonicalService } from '@core/services/canonical.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CanonicalGuard {
	constructor(private readonly canonicalService: CanonicalService) {}

	canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (environment.production) {
			// Remove the query parameters as most of the time it's not needed in the canonical URL.
			const URL = state.url.split('?')[0];

			if (URL.startsWith('/public')) {
				// This is for public pages
				this.canonicalService.createCannonicaUrl(URL.replace('/public/', ''));
			} else {
				this.canonicalService.createCannonicaUrl(URL);
			}
		}

		return true;
	}
}
